<template>
  <div class="siemensPage" v-title="'官方课程'">
    <section class="sec1-box">
      <!-- <img class="sec sec1" src="static/h5/siemens/sec1.png" alt="" /> -->
      <img class="sec sec1" :src="imgs.sec1" v-lazy="imgs.sec1" alt="" />
      <button class="btn-primary" @click="signIn">立即报名 》</button>
    </section>
    <section class="sec2-box">
      <img class="sec sec2" :src="imgs.sec2" v-lazy="imgs.sec2" alt="" />
      <div class="btn-block" @click="listen"></div>
    </section>
      <img class="sec sec3" :src="imgs.sec3" v-lazy="imgs.sec3" alt="" />
      <img class="sec sec4" :src="imgs.sec4" v-lazy="imgs.sec4" alt="" />
    <section class="sec5-box">
      <img class="sec sec5" :src="imgs.sec5" v-lazy="imgs.sec5" alt="" />
      <button class="btn-primary" @click="listen">试听课预约入口</button>
    </section>
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="#f75959">
      <van-swipe-item v-for="(item,index) in team" :key="index">
        <div class="team-dl" v-for="(person,idx) in item" :key="person.name+idx">
          <img :src="person.pic" v-lazy="person.pic" alt="" />
          <div class="name">{{person.name}}</div>
          <div class="title">{{person.title}}</div>
        </div>
      </van-swipe-item>
    </van-swipe>
    <img class="sec sec7" :src="imgs.sec7" v-lazy="imgs.sec7" alt="" />
    <img class="sec sec8" :src="imgs.sec8" v-lazy="imgs.sec8" alt="" />
    <div class="scroll">
      <ul class="scroll-list">
        <li>
          <div class="title">电工 / 维护 / 调试</div>
          <p class="desc">具备一定的电气基础，想要提升自己，转岗做工程师，在职业上有所突破</p>
          <div class="dl">
            <img class="dt" src="static/siemens/role_1.png" alt="">
            <p class="dd">进行系统性的知识学习<br>由浅入深，逐步掌握PLC技能<br>理论和实践结合，边学边练<br>有名师指导，帮助夯实基础<br>可以进行项目实操，学以致用</p>
          </div>
        </li>
        <li>
          <div class="title">电气工程师</div>
          <p class="desc">在编程调试上巩固提升，迎接更大的挑战 突破职业瓶颈</p>
          <div class="dl">
            <img class="dt" src="static/siemens/role_2.png" alt="">
            <p class="dd">原厂大咖讲解，深入了解产品<br>经典案例分析，掌握实战技巧<br>与导师在线交流，探讨知识细节<br>系统化掌握标准化编程<br>获得认证证书，拓展职业边界</p>
          </div>
        </li>
        <li>
          <div class="title">在校学生/应届毕业生</div>
          <p class="desc">学习电气/机电专业，想往电气自动化方向发展，打破“毕业即失业”的魔咒</p>
          <div class="dl">
            <img class="dt" src="static/siemens/role_3.png" alt="">
            <p class="dd">掌握最新、最全的实用型PLC知识<br>获得珍贵的原厂全套学习资料<br>充分利用时间，提高就业竞争力<br>与名师在线交流，夯实学习基础<br>获得认证证书，在简历上脱颖而出</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="sec10-box">
      <van-swipe class="chat-swipe" :autoplay="3000" indicator-color="#f75959">
        <van-swipe-item v-for="(item ,index) in chats" :key="index">
          <img class="chat" :src="item" v-lazy="item" ref="chat" @click="preview(index)" alt="">
        </van-swipe-item>
      </van-swipe>
      <img class="sec sec10" :src="imgs.sec10" v-lazy="imgs.sec10" alt="" />
    </div>
    <section class="sec11-box">
      <div class="form">
        <div class="form-item">
          <input class="input" type="text" v-model="name" placeholder="姓名">
        </div>
        <div class="form-item">
          <input class="input" type="text" v-model="job" placeholder="职业">
        </div>
        <div class="form-item">
          <input class="input" type="tel" v-model="phone" placeholder="手机">
        </div>
        <button class="btn-submit" @click="submit">立即报名</button>
      </div>
      <img class="sec sec11" :src="imgs.sec11" v-lazy="imgs.sec11" alt="" />
    </section>
  </div>
</template>
<script>
import { ImagePreview } from 'vant';
import validate from '@/common/validate.js';
let returnCitySN=window.returnCitySN;
import {
  SignUp,
  getLocation
} from "@/common/api.js";
export default {
  data() {
    return {
      name:'',
      job:'',
      phone:'',
      cityData:{},
      team:[
        [
          {
            name:'毕学武',
            title:'主任工程师',
            pic:require('static/siemens/team/毕学武-主任工程师.png')
          },
          {
            name:'陈华',
            title:'主任工程师',
            pic:require('static/siemens/team/陈华-主任工程师.png')
          },
          {
            name:'杜立军',
            title:'',
            pic:require('static/siemens/team/杜立军.png')
          },
          {
            name:'段礼才',
            title:'主任工程师',
            pic:require('static/siemens/team/段礼才-主任工程师.png')
          },
        ],
        [{
          name:'樊明霞',
          title:'高级工程师',
          pic:require('static/siemens/team/樊明霞-高级工程师.png')
        },
        {
          name:'房丁',
          title:'高级工程师',
          pic:require('static/siemens/team/房丁-高级工程师.png')
        },
        {
          name:'郭开诚',
          title:'主任',
          pic:require('static/siemens/team/郭开诚-主任.png')
        },
        {
          name:'黄文钰',
          title:'专家',
          pic:require('static/siemens/team/黄文钰-专家.png')
        }],
        [{
          name:'雷鸣',
          title:'专家',
          pic:require('static/siemens/team/雷鸣-专家.png')
        },
        {
          name:'李伟',
          title:'高级工程师',
          pic:require('static/siemens/team/李伟-高级工程师.png')
        },
        {
          name:'李晓炜',
          title:'高级工程师',
          pic:require('static/siemens/team/李晓炜-高级工程师.png')
        },
        {
          name:'李欣然',
          title:'高级工程师',
          pic:require('static/siemens/team/李欣然-高级工程师.png')
        }],
        [{
          name:'刘书智',
          title:'专家',
          pic:require('static/siemens/team/刘书智-专家.png')
        },
        {
          name:'马天祥',
          title:'主任工程师',
          pic:require('static/siemens/team/马天祥-主任工程师.png')
        },
        {
          name:'庞开航',
          title:'高级工程师',
          pic:require('static/siemens/team/庞开航-高级工程师.png')
        },
        {
          name:'任俊峰',
          title:'高级工程师',
          pic:require('static/siemens/team/任俊峰-高级工程师.png')
        }],
        [{
          name:'任俊锋',
          title:'',
          pic:require('static/siemens/team/任俊锋.png')
        },
        {
          name:'王广辉',
          title:'高级工程师',
          pic:require('static/siemens/team/王广辉-高级工程师.png')
        },
        {
          name:'徐善海',
          title:'高级工程师',
          pic:require('static/siemens/team/徐善海-高级工程师.png')
        },
        {
          name:'殷悦',
          title:'资深工程师',
          pic:require('static/siemens/team/殷悦-资深工程师.png')
        }],
        [{
          name:'张龙',
          title:'高级工程师',
          pic:require('static/siemens/team/张龙-高级工程师.png')
        },{
          name:'张鹏飞',
          title:'高级工程师',
          pic:require('static/siemens/team/张鹏飞-高级工程师.png')
        },{
          name:'张腾',
          title:'高级工程师',
          pic:require('static/siemens/team/张腾-高级工程师.png')
        },{
          name:'张雪亮',
          title:'专家',
          pic:require('static/siemens/team/张雪亮-专家.png')
        }],
        [{
          name:'张占领',
          title:'主任工程师',
          pic:require('static/siemens/team/张占领-主任工程师.png')
        },{
          name:'张忠权',
          title:'主任工程师',
          pic:require('static/siemens/team/张忠权-主任工程师.png')
        },{
          name:'朱飞翔',
          title:'主任工程师',
          pic:require('static/siemens/team/朱飞翔-主任工程师.png')
        },{
          name:'朱玮',
          title:'高级工程师',
          pic:require('static/siemens/team/朱玮-高级工程师.png')
        }],
      ],
      imgs:{
        sec1:require('static/h5/siemens/sec1.png'),
        sec2:require('static/h5/siemens/sec2.png'),
        sec3:require('static/h5/siemens/sec3.png'),
        sec4:require('static/h5/siemens/sec4.png'),
        sec5:require('static/h5/siemens/sec5.png'),
        sec7:require('static/h5/siemens/sec7.png'),
        sec8:require('static/h5/siemens/sec8.png'),
        sec10:require('static/h5/siemens/sec10.png'),
        sec11:require('static/h5/siemens/sec11.png'),
      },
      chats:[
        require('static/siemens/chat_1.png'),
        require('static/siemens/chat_2.png'),
        require('static/siemens/chat_3.png'),
      ]
    };
  },
  created() {
    if (
      !navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      )
    ) {
      this.$router.replace('/siemensIndex');
    }
  },
  mounted() {
    this.getCity();
  },
  methods: {
    //预览
    preview(index){
      ImagePreview({
        images: this.chats,
        startPosition: index,
      })
    },
    signIn(){
      this.$router.push({
        path:'/siemensSignIn',
        query:{
          ...this.cityData,
          ClientIp:returnCitySN["cip"],
        }
      });
    },
    listen(){
      this.$router.push({
        path:'/trialClass',
        query:{
          ...this.cityData,
          ClientIp:returnCitySN["cip"],
        }
      });
    },
    //获取IP所在地
    getCity(){
      getLocation().then(r=>{
        console.log(r);
        this.cityData = {
          Province: r.content.address_detail.province,
          City: r.content.address_detail.city,
        };
      })
    },
    //提交
    submit(){
      if (this.job.trim() == '') {
          this.$toast('请输入姓名');
          return false;
      }
      if (this.job.trim() == '') {
          this.$toast('请输入职业');
          return false;
      }
      if (this.phone.trim() == '') {
          this.$toast('请输入手机号');
          return false;
      }
      if (!validate.phone(this.phone)) {
          this.$toast('手机号格式不正确');
          return false;
      }
      let params={
        Name:this.name,
        Phone:this.phone,
        Profession:this.job,
        SignType:1,
        ClientIp:returnCitySN["cip"],
        ...this.cityData
      };
      if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
          params['ClientType'] = 2;
      } else {
          params['ClientType'] = 1;
      }
      SignUp(params).then(r=>{
        this.$toast(r.Message);
      })
    }
  },
};
</script>
<style lang="scss">
.siemensPage {
  .sec {
    display: block;
    width: 10rem;
    margin: 0 auto;
  }
  .sec1-box {
    position: relative;
    width: 10rem;
    margin: 0 auto;
    .btn-primary {
      position: absolute;
      width: 56%;
      height: 36px;
      line-height: 36px;
      left: 3.73333%;
      top: 5.013333rem;
      border: 0;
      background-color: #ff7e16;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      &:active {
        background-color: #ff7301;
      }
    }
  }
  .sec2-box {
    position: relative;
    width: 10rem;
    margin: 0 auto;
    .btn-block {
      position: absolute;
      bottom: 1.6rem;
      right: 0.573333rem;
      z-index: 2;
      width: 4.266667rem;
      height: 2.533333rem;
    }
  }
  .sec5-box {
    position: relative;
    width: 10rem;
    margin: 0 auto;
    .btn-primary {
      position: absolute;
      width: 3.733333rem;
      height: 34px;
      line-height: 34px;
      left: 50%;
      bottom: 2.4rem;
      transform: translateX(-50%);
      border: 0;
      background-color: #ff7e16;
      color: #fff;
      font-size: 14px;
      border-radius: 40px;
      &:active {
        background-color: #ff7301;
      }
    }
  }
  .scroll{
    overflow-x: auto;
    max-width: 10rem;
    margin: 0 auto 40px;
    .scroll-list{
      height: 240px;
      width: 1020px;
      padding-left: 15px;
      >li{
        float: left;
        width: 320px;
        height: 230px;
        border-radius: 10px;
        box-shadow: 0px 3px  8px 0px rgba(0, 0, 0, 0.14);
        background: #ECF2F5;
        text-align: center;
        padding: 10px;
        margin-right: 15px;
      }
      .title{
        font-size: 16px;
        color: #333;
        line-height: 24px;
        font-weight: bold;
        margin-bottom: 15px;
      }
      .desc{
        font-size: 14px;
        color: #333;
        line-height: 24px;
        margin-bottom: 10px;
      }
      .dl{
        overflow: hidden;
      }
      .dt{
        float: left;
        width: 110px;
        height: 110px;
        border-radius: 50%;
        margin-right: 10px;
      }
      .dd{
        float: left;
        width: 180px;
        text-align: left;
      }
    }
  }
  .van-swipe__indicator{
    background-color: rgba(0,0,0,0.6);
  }
  .my-swipe{
    width: 8.8rem;
    margin: 0 auto;
    .van-swipe-item {
      overflow: hidden;
      padding-bottom: 20px;
    }
    .team-dl {
      width: 46%;
      float: left;
      overflow: hidden;
      margin-left: 2%;
      margin-right: 2%;
      margin-bottom: 10px;
      img{
        width: 4rem;
        height: 4.44rem;
        display: block;
        margin: 0 auto;
      }
      .name{
        font-size: 14px;
        color: #333;
        line-height: 24px;
        padding-left: 10px;
      }
      .title{
        font-size: 12px;
        color: #333;
        line-height: 20px;
        height: 20px;
        padding-left: 10px;
      }
    }
  }
  .sec10-box {
    position: relative;
    width: 10rem;
    margin: 0 auto;
    .chat-swipe{
      position: absolute;
      left: 0.72rem;
      top: 2.933333rem;
      width: 3.333333rem;
    }
    .van-swipe-item {
      overflow: hidden;
    }
    .chat{
      width: 3.333333rem;
      display: block;
    }
  }
  .sec11-box{
    position: relative;
    width: 10rem;
    margin: 0 auto;
    .form{
      width: 8rem;
      position: absolute;
      left: 50%;
      top: 1.333333rem;
      margin-left: -4rem;
      .form-item{
        width: 100%;
        margin-bottom: 20px;
      }
      .input{
        height: 40px;
        border: 1px solid #fff;
        background-color: transparent;
        color: #fff;
        font-size: 16px;
        padding: 8px 12px;
        line-height: 22px;
        display: block;
        width: 100%;
        &::placeholder{
          color: #fff;
        }
      }
      .btn-submit{
        display: block;
        width: 100%;
        height: 40px;
        background-color: #FF7E16;
        font-size: 16px;
        color: #fff;
        line-height: 40px;
      }
    }
  }
}
</style>
